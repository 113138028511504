import axios from 'axios';
import { Method } from 'axios';
import { userIdFromToken } from "@/helper";

const tryRequest = async (method: Method, ref: string, data?: object, token?: string) => {
  try {
    const params: unknown = { method: method, url: ref };
    if(token) {
      params['headers'] = { 'Authorization': `Bearer ${token}` };
    }
    if(data) {
      params['data'] = data;
    }
    return await axios(params);
  } catch (error) {
    return error.response;
  }
}

export const login = async (userCredentials: object) => {
  return tryRequest('post', '/api/auth/loginlocal', userCredentials);
}

export const refresh = async (userToken: string) => {
  return tryRequest('get', '/api/auth/refresh', {}, userToken);
}

export const getUser = async (userToken: string) => {
  const userid = userIdFromToken(userToken);
  return tryRequest('get', `/api/users/${userid}`, {}, userToken);
}

export const editUser = async (userToken: string, userid: number, profileChanges: Record<string, unknown>) => {
  if(userid === -1) {
    userid = userIdFromToken(userToken);
  }

  if(profileChanges.roles !== undefined && Array.isArray(profileChanges.roles)) {
    console.log('Here')
    const rolesResponse = await (getRoles(userToken) as Promise<{data: Array<{id: number, name: string}>}>)
    const definedRoles = rolesResponse.data
    console.log(definedRoles)
    for(let role of profileChanges.roles) {
      const newRole = definedRoles.find((definedRole) => definedRole.name === role)
      role = newRole.id
    }
  }

  return tryRequest('patch', `/api/users/${userid}`, profileChanges, userToken);
}

export const addUser = async (userToken: string, user: object) => {
  return tryRequest('post', `/api/users/`, user, userToken);
}

export const deleteUser = async (userToken: string, userid: number) => {
  return tryRequest('delete', `/api/users/${userid}`, {}, userToken);
}

export const addRole = async (userToken: string, role: object) => {
  return tryRequest('post', `/api/roles`, role, userToken);
}

export const editRole = async (userToken: string, roleid: number, roleChanges: object) => {
  return tryRequest('patch', `/api/roles/${roleid}`, roleChanges, userToken);
}

export const deleteRole = async (userToken: string, roleid: number) => {
  return tryRequest('delete', `/api/roles/${roleid}`, {}, userToken);
}

export const getRoles = async (userToken: string) => {
  return tryRequest('get', `/api/roles/`, {}, userToken);
}

export const getUsers = async (userToken: string) => {
  return tryRequest('get', `/api/users/`, {}, userToken);
}

export const getAllPipelines = async (userToken: string) => {
  return tryRequest('get', `/pipeline/available/`, {}, userToken);
}

export const getRunningPipelines = async (userToken: string) => {
  return tryRequest('get', `/pipeline/`, {}, userToken);
}

export const startPipeline = async (userToken: string, id: number) => {
  return tryRequest('put', `/pipeline/${id}`, {}, userToken);
}

export const stopPipeline = async (userToken: string, id: number) => {
  return tryRequest('delete', `/pipeline/${id}`, {}, userToken);
}

export const getThings = async () => {
  return tryRequest('get', `/td`);
}

export const getThing = async (id: number) => {
  return tryRequest('get', `/td/${id}`);
}
