
import { defineComponent } from 'vue'

import VueTagsInput from '@sipec/vue3-tags-input'

export default defineComponent({
  components: {
    VueTagsInput,
  },
  props: {
    modelValue: { type: [String, Number, Boolean, Object, Array], required: false, default: undefined },
    inputType: {type: String, required: true},
    placeholder: { type: [String, undefined], required: false, default: undefined },
    addClass: { type: [String, undefined], required: false, default: undefined },
    radioOptions: { type: [Array<string>, undefined], required: false, default: undefined },
    checkboxOptions: { type: [Array<string>, undefined], required: false, default: undefined },
    ratingSelectHandler: { type: [Function, undefined], required: false, default: undefined },
    isDisabled: { type: Boolean, required: false },
  },
  emits: ['update:model-value'],
  computed: {
    inputValue: {
      get() {
        return this.modelValue
      },
      set(value: unknown) {
        this.$emit("update:model-value", value);
      }
    }
  }

})
