<template>
  <div class="p404">
    <h1 class="p404-header">404</h1>
    <p class="p404-desc">The content you are looking for is not available.</p>
  </div>
</template>

<style scoped>
.p404{
  margin: auto;
}

.p404-header {
  margin: 15vw;
  font-size: 30vw;
  text-align: center;
}

.p404-desc {
  margin: 0;
  padding: 0;
  text-align: center;
}
</style>