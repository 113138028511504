import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e1c89cd"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for", "form"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.addClass)
  }, [
    _createElementVNode("label", {
      class: _normalizeClass(_ctx.addClass),
      for: _ctx.labelFor,
      form: _ctx.form
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 10, _hoisted_1),
    (_ctx.addDesc)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: _normalizeClass(_ctx.addClass)
        }, _toDisplayString(_ctx.addDesc), 3))
      : _createCommentVNode("", true)
  ], 2))
}