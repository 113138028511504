import { GetterTree } from 'vuex';
import { AuthState } from './types';
import { userIdFromToken, userNameFromToken, userRolesFromToken } from "@/helper";

export const getters: GetterTree<AuthState, Record<string, never>> = {
  getToken(state): string {
    return state.token;
  },
  getUser(state): string {
    return userIdFromToken(state.token);
  },
  getUsername(state): string {
    return userNameFromToken(state.token);
  },
  isConnected(state): boolean {
    return !!state.token;
  },
  isAdmin(state): boolean {
    return userRolesFromToken(state.token).includes("admin");
  }
}
