import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.addClass),
    onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDropDown=true)),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDropDown=false))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.addClass, "drop-down-label"])
    }, [
      _renderSlot(_ctx.$slots, "dropDownLabel", {}, undefined, true)
    ], 2),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(["options", _ctx.addClass]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeOnClick ? _ctx.showDropDown = false : null))
    }, [
      _renderSlot(_ctx.$slots, "dropDownOptions", {}, undefined, true)
    ], 2), [
      [_vShow, _ctx.showDropDown]
    ])
  ], 34))
}