import { vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelCheckbox as _vModelCheckbox, vModelDynamic as _vModelDynamic, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eb362026"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder"]
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]
const _hoisted_4 = ["placeholder", "type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_tags_input = _resolveComponent("vue-tags-input")!

  return (_ctx.inputType === 'textarea')
    ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
        key: 0,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
        class: _normalizeClass(_ctx.addClass),
        placeholder: _ctx.placeholder
      }, null, 10, _hoisted_1)), [
        [_vModelText, _ctx.inputValue]
      ])
    : (_ctx.inputType === 'tag')
      ? (_openBlock(), _createBlock(_component_vue_tags_input, {
          key: 1,
          tags: _ctx.inputValue,
          "add-tag-on-blur": true,
          limit: 10,
          "read-only": _ctx.isDisabled,
          "add-tag-on-keys": [13, 188, 9, 32]
        }, null, 8, ["tags", "read-only"]))
      : (_ctx.inputType === 'radio')
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass([_ctx.addClass, "radio-container"])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.radioOptions, (element, index) => {
              return (_openBlock(), _createElementBlock("label", {
                key: index,
                class: _normalizeClass([_ctx.addClass, "radio-option-container"])
              }, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputValue) = $event)),
                  type: "radio",
                  class: _normalizeClass([_ctx.addClass, "radio"]),
                  value: element
                }, null, 10, _hoisted_2), [
                  [_vModelRadio, _ctx.inputValue]
                ]),
                _createTextVNode(" " + _toDisplayString(element), 1)
              ], 2))
            }), 128))
          ], 2))
        : (_ctx.inputType === 'checkbox')
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              class: _normalizeClass([_ctx.addClass, "checkbox-container"])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checkboxOptions, (element, index) => {
                return (_openBlock(), _createElementBlock("label", {
                  key: index,
                  class: _normalizeClass([_ctx.addClass, "checkbox-option-container"])
                }, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.inputValue) = $event)),
                    type: "checkbox",
                    class: _normalizeClass([_ctx.addClass, "checkbox"]),
                    value: element
                  }, null, 10, _hoisted_3), [
                    [_vModelCheckbox, _ctx.inputValue]
                  ]),
                  _createTextVNode(" " + _toDisplayString(element), 1)
                ], 2))
              }), 128))
            ], 2))
          : _withDirectives((_openBlock(), _createElementBlock("input", {
              key: 4,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.inputValue) = $event)),
              class: _normalizeClass(_ctx.addClass),
              placeholder: _ctx.placeholder,
              type: _ctx.inputType,
              disabled: _ctx.isDisabled
            }, null, 10, _hoisted_4)), [
              [_vModelDynamic, _ctx.inputValue]
            ])
}