

import mFormElement from "@/components/02_molecules/mFormElement.vue";
import aButton from "@/components/01_atoms/aButton.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    mFormElement,
    aButton
  },
  props: {
    formFields: { type: Object, required: true },
    buttonLabel: { type: String, required: false, default: "Button" },
    submitFunction: { type: Function, required: true },
    enterToSubmit: { type: Boolean, required: false, default: false},
    addClass: { type: String, required: false, default: undefined},
  },
  data() {
    return {
      formInputData: {}
    }
  },
  created() {
    for(const element of this.formFields) {
      this.formInputData[element.variable] = undefined
    }
  }
})
