
import { defineComponent } from "vue";
import oHeader from "@/components/03_organisms/oHeader.vue";
import oFooter from "@/components/03_organisms/oFooter.vue";

export default defineComponent({
  name: "App",
  components: {
    oHeader,
    oFooter
  }
});
