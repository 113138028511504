<template>
  <div class="about-page-content">
    <div class="about-content">
      <h1>IoT Remote Lab</h1>
      <div>
        <h2>About</h2>
        <p>
          IoT Remote Lab is a course offered by the <a href="https://www.ei.tum.de/en/esi/home/">Associate Professorship of Embedded Systems and Internet of Things</a> of the Technical University of Munich, Germany. Other pages are reserved for participants of the course and others who are granted access by the maintainers of the course.
        </p>
        <p>
          This course takes place every semester during the lecture periods i.e October - February and April - July. 
          Outside of these dates, the lab and the setup is still active and usable (and also more suited) for research purposes.
        </p>
        <h3> Contents </h3>
        <p>
          The website is used for two main purposes.
          Firstly, the Thing Descriptions of the devices are made accesible to the users. Users can then write the required programs and requests to interact with the devices over the Internet.
          Secondly, 3 live video streams are provided to the users so that they can verify that their intended interactions are realized.
        </p>
        <h3> Access and Contact Information </h3>
          <h4>Asking for Access </h4>
          <p>
            You can ask for access rights to the streams and Thing Descriptions pages.
            You should explain your intention on using the setup, your affiliation, the duration you wish to have access for and contact details.
            After contacting the person below, you will get a username and password.
          </p>
          <h4>Contact</h4>
          <p>
            For anything, please contact Fady Salama at <i>fady(dot)salama(at)tum(dot)de</i>
          </p>
      </div>
      <div>
        <h2>Acknowledgements</h2>
        <p>
          This Website and its accompanying lab is made possible with the support of the TUM Department of Electrical and Computer Engineering under the framework of the Studienzuschüsse projects. 
        </p>
        <h3>People</h3>
        We would like to acknowledge all the active and past contributors of this project:
        <h4>Active Contributors</h4>
          <ul>
            <li>
              <a href="https://www.linkedin.com/in/fady-salama-2158031a4/"> Fady Salama</a> for setting up and managing the IoT remote lab course.
            </li>
            <li>
              <a href="https://www.linkedin.com/in/aanchal-chugh"> Aanchal Chugh</a> for implementing new functionalities along with identification and correction of bugs.
            </li>
        </ul>
        <h3>Past Contributors</h3>
          <ul>
            <li>
              <a href="https://www.linkedin.com/in/jonas-fingerling"> Jonas Fingerling </a> for the initial server implementation and planning the software architecture.
            </li>
            <li>
              <a href="https://www.linkedin.com/in/ege-korkan/"> Ege Korkan</a> for setting up and managing the course.
            </li>
            <li>
              Andreas Schrägle for the server implementation involving all the front-end and back-end work.
            </li>            
            <li>
              All the students who have contributed with different device implementations! Thanks to Emil Daporta, Marcus Schmidt, Hassib Belhaj.
            </li>
          </ul>
      </div>
      <div>
        <h2>W3C WoT</h2>
        <p>
          This lab setup uses the Web of Things (WoT) standards proposed by the the World Wide Web Consortium (W3C) Web of Things Working Group.
          For the newcomers, below are different Web pages that we think are important for understanding the Web of Things.
        </p>
        <ul>
          <li>
            <p>
            <a href="https://www.w3.org/WoT/" target="_blank">W3C WoT Home Page</a>: Here you can find different news and links to other WoT related pages in the W3C.
            </p>
          </li>
          <li>
            <p>
            <a href="https://www.w3.org/TR/wot-thing-description/" target="_blank">Thing Description Specification</a>: This is the core specification of the W3C WoT Working Group, which allows to describe the network interfaces of individual Things, like an API description. 
            The link is for the stable and published version but you can check out the latest changes if you click at the Editor's Draft that is dynamically generated from <a href="https://github.com/w3c/wot-thing-description/">the GitHub repository </a>. You can also open issues in this repository.
            </p>
          </li>
          <li>
            <p>
            <a href="https://www.w3.org/TR/wot-architecture/" target="_blank"> Web of Things Architecture</a>: This specification lists the different use cases for the WoT, which the other task forces can use to change their specifications and bring new features. Very importantly, it explains the WoT operations in <a href="https://www.w3.org/TR/wot-architecture/#sec-wot-architecture"> the Section 6 </a>.
            The link is for the stable and published version but you can check out the latest changes if you click at the Editor's Draft that is dynamically generated from <a href="https://github.com/w3c/wot-architecture/">the GitHub repository </a>. You can also open issues in this repository.
            </p>
          </li>
          <li>
            <p>
            <a href="http://plugfest.thingweb.io/playground/" target="_blank">Thing Description Playground</a>: This is the official validator for Thing Descriptions. You can also get example Thing Descriptions to better understand the validation process.
            It is important to note that the third validation steps checks whether there is anything left to be assumed by a TD reader or whether everything is clear.
            </p>
          </li>
          <li>
            <p>
            <a href="https://github.com/eclipse/thingweb.node-wot" target="_blank">Eclipse Thingweb node-wot</a>: Eclipse Foundation has the Thingweb project that is providing software for the W3C Web of Things. Most importantly, the node-wot library implements <a href="https://www.w3.org/TR/wot-scripting-api/"> the W3C WoT Scripting API</a>, which allows to program Thing and Consumer implementations in a protocol agnostic way. We also have a <a href="https://www.youtube.com/watch?v=wDX45dsD4GM&t=414s">tutorial</a> on how to use it.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
.about-page-content {
  display: grid;
  grid-auto-flow: column;
  margin: 0 5vw;
}

.about-content {
  margin: 0 5vw;
}

.about-content h1 {
  text-align: center;
}
</style>
