
import oProjectTagContainer from "@/components/03_organisms/oProjectTagContainer.vue";

export default {
  components: {
    oProjectTagContainer
  },
  data() {
    return {
      PAGE_SIZE: 12
    }
  },
  computed: {
    page() {
      return this.$store.getters['library/getPage']
    },
    totalPages() {
      return this.$store.getters['library/getTotalPages']
    },
    totalResults() {
      return this.$store.getters['library/getTotalResults']
    },
    totalDocs() {
      return this.$store.getters['library/getTotalDocs']
    },
    loadedProjects() {
      return this.$store.getters['library/getLoadedProjects']
    }
  },
  async created() {
      this.getProjects();
    },
  methods: {
    getProjects() {
      return this.$store.dispatch('library/getThings')
    },
    async getPage(page: number) {
      this.$router.push({
        name: "Thing Descriptions",
        query: {
          ...this.$route.query,
          page: page.toString()
        }
      })
    }
  },
}
