
    import mCreateItemForm from '@/components/02_molecules/mCreateItemForm.vue';
    import mRow from '@/components/02_molecules/mRow.vue';
    import { defineComponent } from 'vue';
    
    export default defineComponent({
        components: {
            mRow,
            mCreateItemForm
        },
        props: {
            fields:     { type: Array, required: true},
            classes:    { type: String, required: false, default: 'table table-grid'},
            editable:   { type: Boolean, required: false, default: true },
            creatable:  { type: Boolean, required: false, default: true },
            deletable:  { type: Boolean, required: false, default: true },
            api:        { type: Object, required: true }
        },
        data() {
            return {
                tableFields: [],
                tableData: []
            }
        },
        watch: {
            api: {
                handler: 'loadData',
                immediate: false,
                deep: false,
            }
        },
        mounted() {
	        this.tableFields = this.fields;
        },
        methods:{
            async loadData() {
                const response = await this.api.load(this.api.token);
                if(response.status === 200) {
                    this.tableData = response.data;
                } else {
                    console.log(response);
                }
	        },
            destroy(item: unknown) {
                // destroy from the collection
                const index = this.tableData.indexOf(item);
                this.tableData.splice(index, 1);
            },
            update(item: unknown) {
                // update in the collection
                const index = this.tableData.indexOf(item);
                this.tableData[index] = item;
            },
            save(item: unknown) {
                // add a new item to the collection
                this.tableData.push(item);
            }
        }
    })
