import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 3 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aButton = _resolveComponent("aButton")!
  const _component_aInput = _resolveComponent("aInput")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.create)
      ? (_openBlock(), _createBlock(_component_aButton, {
          key: 0,
          "add-class": 'edit',
          "btn-event": "btn-edit",
          onBtnEdit: _ctx.edit
        }, {
          default: _withCtx(() => [
            _createTextVNode("Add")
          ]),
          _: 1
        }, 8, ["onBtnEdit"]))
      : _createCommentVNode("", true),
    (_ctx.create)
      ? (_openBlock(), _createBlock(_component_aButton, {
          key: 1,
          "add-class": 'save',
          "btn-event": "btn-save",
          onBtnSave: _ctx.save
        }, {
          default: _withCtx(() => [
            _createTextVNode("Save")
          ]),
          _: 1
        }, 8, ["onBtnSave"]))
      : _createCommentVNode("", true),
    (_ctx.create)
      ? (_openBlock(), _createBlock(_component_aButton, {
          key: 2,
          "add-class": 'cancel-edit',
          "btn-event": "btn-cancel",
          onBtnCancel: _ctx.cancel
        }, {
          default: _withCtx(() => [
            _createTextVNode("Cancel")
          ]),
          _: 1
        }, 8, ["onBtnCancel"]))
      : _createCommentVNode("", true),
    (_ctx.create)
      ? (_openBlock(), _createElementBlock("form", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              (field.creatable)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("label", {
                      for: field.name
                    }, _toDisplayString(field.title), 9, _hoisted_3),
                    _createVNode(_component_aInput, {
                      id: field.name,
                      modelValue: _ctx.createForm[field.name],
                      "onUpdate:modelValue": ($event: any) => ((_ctx.createForm[field.name]) = $event),
                      "input-type": field.type,
                      "add-class": "form-control"
                    }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "input-type"])
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}