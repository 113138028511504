
import VueJsonPretty from "vue-json-pretty";
import 'vue-json-pretty/lib/styles.css';
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    VueJsonPretty
  },
  props: {
    content: { type: Object, required: true }
  }
})
