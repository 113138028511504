<template>
  <div class="footer-container">
    <label class="footer-el">Copyrights © 2023 TUM EI ESI. All rights reserved. <a href="/impressum" target="_blank">Impressum</a> </label>
  </div>
</template>

<style scoped>
.footer-container {
  clear: both;
  background: #1c1c1c;
  padding: 10px;
  overflow: hidden;
  width: 100%;
  height: 50px;
  border-top: 5px solid #397fc5;
  text-align: center;
}

.footer-el {
  color: #FFFAF0;
  font-size: 14px;
}

a {
  color: hotpink;
}
</style>
