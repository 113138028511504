import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aInput = _resolveComponent("aInput")!
  const _component_aButton = _resolveComponent("aButton")!

  return (_openBlock(), _createElementBlock("tr", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
      return (_openBlock(), _createElementBlock("td", {
        key: field.name
      }, [
        _createVNode(_component_aInput, {
          "model-value": _ctx.item[field.name],
          "input-type": field.type,
          "add-class": "form-control",
          placeholder: field.name,
          "is-disabled": !(_ctx.editMode && field.editable)
        }, null, 8, ["model-value", "input-type", "placeholder", "is-disabled"])
      ]))
    }), 128)),
    (_ctx.editable || _ctx.deletable)
      ? (_openBlock(), _createElementBlock("td", _hoisted_1, [
          (!_ctx.editMode && _ctx.editable)
            ? (_openBlock(), _createBlock(_component_aButton, {
                key: 0,
                "btn-event": "btn-edit",
                "add-class": 'edit',
                onBtnEdit: _ctx.edit
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Edit ")
                ]),
                _: 1
              }, 8, ["onBtnEdit"]))
            : _createCommentVNode("", true),
          (_ctx.editMode && _ctx.editable)
            ? (_openBlock(), _createBlock(_component_aButton, {
                key: 1,
                "btn-event": "btn-cancel-edit",
                "add-class": 'cancel-edit',
                onBtnCancelEdit: _ctx.cancelEdit
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Cancel ")
                ]),
                _: 1
              }, 8, ["onBtnCancelEdit"]))
            : _createCommentVNode("", true),
          (_ctx.editMode && _ctx.editable)
            ? (_openBlock(), _createBlock(_component_aButton, {
                key: 2,
                "btn-event": "btn-update",
                "add-class": 'update',
                onBtnUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.update(_ctx.item, _ctx.editForm)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Update ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.editMode && _ctx.deletable)
            ? (_openBlock(), _createBlock(_component_aButton, {
                key: 3,
                "btn-event": "btn-destroy",
                "add-class": 'destroy',
                onBtnDestroy: _cache[1] || (_cache[1] = ($event: any) => (_ctx.destroy(_ctx.item)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Delete ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}