import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mFormElement = _resolveComponent("mFormElement")!
  const _component_aButton = _resolveComponent("aButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-container", _ctx.addClass])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formFields, (element, index) => {
      return (_openBlock(), _createBlock(_component_mFormElement, {
        key: index,
        modelValue: _ctx.formInputData[element.variable],
        "onUpdate:modelValue": ($event: any) => ((_ctx.formInputData[element.variable]) = $event),
        "label-content": element.label,
        "label-description": element.desc,
        "input-type": element.type,
        "input-placeholder": element.placeholder,
        "radio-options": element.radioOptions,
        "checkbox-options": element.checkboxOptions,
        "add-class": _ctx.addClass,
        onKeyup: _cache[0] || (_cache[0] = _withKeys(_withModifiers(e => _ctx.enterToSubmit && _ctx.submitFunction(_ctx.formInputData), ["stop"]), ["enter"]))
      }, null, 8, ["modelValue", "onUpdate:modelValue", "label-content", "label-description", "input-type", "input-placeholder", "radio-options", "checkbox-options", "add-class"]))
    }), 128)),
    _createVNode(_component_aButton, {
      "add-class": _ctx.addClass,
      "btn-event": 'form-btn-triggered',
      onFormBtnTriggered: _cache[1] || (_cache[1] = ($event: any) => (_ctx.submitFunction(_ctx.formInputData)))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.buttonLabel), 1)
      ]),
      _: 1
    }, 8, ["add-class"])
  ], 2))
}