import { createStore } from "vuex";

import { authentication } from './modules/authentication';
import { library } from './modules/library';

const store = createStore({
  modules: {
    authentication,
    library
  }
})
export default store
