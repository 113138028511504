
import oForm from "@/components/03_organisms/oForm.vue";

export default {
  components: {
    oForm,
  },
  data() {
    return {
      errorMessage: "",
      validationMessage: false,
      loginFormFields: [
        {
          type: "text",
          label: "Username",
          placeholder: "Enter your username here",
          variable: "username"
        },
        {
          type: "password",
          label: "Password",
          placeholder: "Enter your password here",
          variable: "password"
        }
      ]
    }
  },
  methods: {
    login(userCredentials: unknown) {
      return this.$store.dispatch('authentication/login', userCredentials)
    },
    async attemptLogin(loginFormData: unknown) {
      this.login(loginFormData).then(() => {
        this.$router.push({
          name: "Homepage"
        });
      }).catch((error) => {
        if(error.data?.message) this.errorMessage = error.data.message;
      });
    },

    resetMessage(){
      this.errorMessage = "";
      this.validationMessage = false;
    }
  }
}
