import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39c12edc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "video-container" }
const _hoisted_2 = ["id", "width", "height", "muted"]
const _hoisted_3 = ["url", "type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("video", {
      id: 'video' + _ctx.id,
      autoplay: "",
      width: _ctx.width,
      height: _ctx.height,
      muted: _ctx.muted
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sources, (src, index) => {
        return (_openBlock(), _createElementBlock("source", {
          key: index,
          url: src.url,
          type: src.type
        }, null, 8, _hoisted_3))
      }), 128))
    ], 8, _hoisted_2)
  ]))
}