import { ActionTree } from 'vuex';

import { LibraryState } from './types';
import * as api from '@/api';

export const actions: ActionTree<LibraryState, Record<string, never>> = {
  async getThings({ commit }){
    const response = await api.getThings();

    const totalResults = response.data.length;
    commit('setPage', 1);
    commit('setTotalPages', Math.ceil(totalResults/12));
    commit('setTotalResults', totalResults);
    commit('setLoadedProjects', response.data);
    commit('setTotalDocs', totalResults);
  }
}
