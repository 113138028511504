
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    labelFor: { type: String, required: false, default: undefined },
    addDesc:  { type: String, required: false, default: undefined },
    form:     { type: String, required: false, default: undefined },
    addClass: { type: String, required: false, default: undefined }
  }
})
