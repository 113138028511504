import { ActionTree } from 'vuex';

import { AuthState } from './types';
import { login, refresh } from '@/api';
import { iatFromToken, expFromToken } from '@/helper';

export const actions: ActionTree<AuthState, Record<string, never>> = {
  async login({ commit }, payload) {
    const response = await login(payload);
    if(response.status === 200) {
      sessionStorage.setItem('user-token', response.data.token);

      commit('setToken', response.data.token);
    } else {
      sessionStorage.removeItem('user-token');
      throw new Error(response)
    }
  
  },
  async refresh({ commit, getters }) {
    const token = getters.getToken;
    const currentDate = Number(Date.now() / 1000);
    const iat = iatFromToken(token);
    const exp = expFromToken(token);
    const validFor = exp - currentDate;
    if(isNaN(iat) && isNaN(exp)) {
      console.log("No numbers found, you don't seem to have a valid token")
      throw new Error("No numbers found, you don't seem to have a valid token")
    } else if(validFor < 0) {
      console.log(`Token expired ${-validFor} seconds ago, not not refreshing`)
      throw new Error(`Token expired ${-validFor} seconds ago, not not refreshing`)
    } else if(validFor > (exp - iat)*0.75) {
      console.log(`not refreshing token: ${validFor} > (exp - iat)*0.75`);
      return;
    } else {
      console.log(`refreshing token: ${validFor} <  (exp - iat)*0.75`);
      const response = await refresh(token);

      if(response.status === 200) {
        sessionStorage.setItem('user-token', response.data.token);

        commit("setToken", response.data.token);

        return response
      } else {
        sessionStorage.removeItem('user-token');

        throw new Error(response);
      }
    }
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      commit("removeToken");
      sessionStorage.removeItem("user-token");
      resolve(undefined);
    });
  }
}
