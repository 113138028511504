import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31fca79e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "library-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_oProjectTagContainer = _resolveComponent("oProjectTagContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_oProjectTagContainer, {
      projects: $options.loadedProjects,
      class: "library-content-btm"
    }, null, 8, ["projects"])
  ]))
}