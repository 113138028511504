
import aButton from "@/components/01_atoms/aButton.vue";
import aNavLink from "@/components/01_atoms/aNavLink.vue";
import mInfoBox from "@/components/02_molecules/mInfoBox.vue";

import { getThing } from "@/api";

export default {
  components: {
    aButton,
    aNavLink,
    mInfoBox
  },
  data() {
    return {
      project: {
        title: "",
        id: "",
        name: "",
        author: {},
        update: Date.now,
        summary: "",
        repoUrl: null,
        description: "",
        thingDesc: {},
        topic: [],
        platform: [],
        type: "",
        complexity: "",
        avg_rating: 0,
        raters: {},
        tags: []
      },
      base64TD: ""
    }
  },
  computed: {
    sidebar() {
      const sidebar = [
        {
          title: "General Information",
          id: "info",
          categories: [
            {
              label: "Status: ",
              type: "text", // FIXME
              content: this.project.status
            }
          ]
        }
      ];

      return sidebar;
    }
  },
  async created() {
    const response = await getThing(parseInt(this.$route.params.id));
    if (response.status === 200) {
      this.project = response.data;
      this.base64TD = "data:application/json;charset=utf-8," + encodeURI(JSON.stringify(this.project.content, null, 2));
    } else if (response.status === 404) {
      this.$router.push({ name: "404" });
    }
  },
  methods: {
    getContent() {
      if (this.$route.name === "Project General") {
        return this.project.description;
      }
      return this.project.content;
    }
  }
}
