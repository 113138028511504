import { createRouter, createWebHistory } from "vue-router";

import pAbout from "@/components/04_pages/pAbout.vue";
import pLogin from "@/components/04_pages/pLogin.vue";
import pProfile from "@/components/04_pages/pProfile.vue";
import p404 from "@/components/04_pages/p-404.vue";
import pHomepage from "@/components/04_pages/pHomepage.vue";
import pImpressum from "@/components/04_pages/pImpressum.vue";
import pProject from "@/components/04_pages/pProject.vue";
import aJsonBox from "@/components/01_atoms/aJsonBox.vue";
import aMarkdownBox from "@/components/01_atoms/aMarkdownBox.vue";
import pUserManagement from "@/components/04_pages/pUserManagement.vue";
import pStreams from "@/components/04_pages/pStreams.vue";
import pThingDescriptions from "@/components/04_pages/pThingDescriptions.vue";

const router =  createRouter({
  "history": createWebHistory(process.env.BASE_URL),
  "routes": [
    {
      path: "/",
      name: "Homepage",
      component: pHomepage
    },
    {
      path: "/about",
      name: "About",
      component: pAbout
    },
    {
      path: "/impressum",
      name: "Impressum",
      component: pImpressum
    },
    {
      path: "/login",
      name: "Login",
      component: pLogin
    },
    {
      path: "/streams",
      name: "Streams",
      component: pStreams
    },
    {
      path: "/things",
      name: "Thing Descriptions",
      component: pThingDescriptions
    },
    {
      path: "/things/:id",
      component: pProject,
      children: [
        {
          path: 'general',
          name: 'Project General',
          component: aMarkdownBox
        },
        {
          path: 'td',
          name: 'Project ThingDesc',
          component: aJsonBox
        }
      ]
    },
    {
      path: "/profile",
      name: "Profile",
      component: pProfile
    },
    {
      path: "/usermgmt",
      name: "User Management",
      component: pUserManagement
    },
    {
      path: "/:catchAll(.*)",
      name: "404",
      component: p404
    }
  ]
})

export default router