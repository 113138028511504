
import mVideoStream from "@/components/02_molecules/mVideoStream.vue";

import { getAllPipelines, getRunningPipelines } from "@/api";

export default {
    components: {
        mVideoStream
    },
    data() {
        return {
            pipelines: []
        }
    },
    computed: {
        authToken(): string {
            return this.$store.getters['authentication/getToken']
        },
        isAdmin(): boolean {
            return this.$store.getters['authentication/isAdmin']
        }
    },
    async created() {
        this.openWebSocket();
        const running = await getRunningPipelines(this.authToken);
        if (this.isAdmin) {
            const all = await getAllPipelines(this.authToken);
            console.log("Pipelines (available): %o", all);
            if (running.status === 200 && all.status === 200) {
                this.pipelines = {...all.data, ...running.data };
            }
        }
        console.log("Pipelines (running): %o", running);
        if (running.status === 200 && !this.isAdmin) {
            this.pipelines = running.data;
        }
    },
    async unmounted() {
        if(this.$socket) this.$socket.close();
    },
    methods: {
            openWebSocket() {
                const proto = (location.protocol == 'https:') ? "wss" : "ws";
                this.$connect(proto + "://" + location.host + "/control?access_token=" + this.authToken, {
                    reconnection: true
                });
            }
    }
}
