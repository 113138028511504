import { createApp } from "vue";
import VueNativeSock from "vue-native-websocket-vue3";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store/store";

const app = createApp(App)

const unAuthPaths = [ "/login", "/impressum", "/about", "/","/loadTimetable","/timetable"];
router.beforeEach((to, from, next) => {
  if (!(unAuthPaths.includes(to.path)) && !store.getters["authentication/isConnected"]) {
    if(to.path.includes("timetable")) {
        next();
    }
    else
      next("/login");
  }
  else {
    next();
  }
});

// autorefresh token every 5 minutes, if needed
setInterval(() => {
  store.dispatch("authentication/refresh").then(() => {return}, () => {
    if(!unAuthPaths.includes(router.currentRoute.value.path)) {
      router.push("/login")
    }
  })
}, 5*60*1000)

// Vue.use(VueMatomo, {
//   host: 'https://matomo.wotify.org',
//   siteId: 3,
//   router: router,
//   enableHeartBeatTimer: true,
// });
app.use(router)
app.use(store)
app.use(VueNativeSock, "ws://no", {connectManually: true, format: undefined})
app.mount("#app")