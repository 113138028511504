
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    addClass: { type: String, required: false, default: undefined },
    closeOnClick: Boolean
  },
  data() {
    return {
      showDropDown: false
    }
    
  }
})
