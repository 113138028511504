
import mProjectTag from "@/components/02_molecules/mProjectTag.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    mProjectTag
  },
  props: {
    projects: { type: Array<{ id: number | string }>, required: true },
    addClass: { type: String, required: false, default: undefined}
  }
})
