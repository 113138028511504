
    import aButton from "@/components/01_atoms/aButton.vue";
    import aInput from "@/components/01_atoms/aInput.vue";

    import { defineComponent } from 'vue'

    export default defineComponent({
        components: {
            aButton,
            aInput
        },
        props: {
            fields: {type: Array<{creatable: boolean, type: string, title: string, name: string}>, required: true},
            api: {type: Object, required: true}
        },
        emits: ["save"],
        data() {
            return {
                create: false,
                createForm: {}
            }            
        },
        methods: {
            edit() {
                this.create = true
            },
            async save() {
                const response = await this.api.save(this.api.token, this.createForm);
                if(response.status === 200) {
                    //trigger event to collection
                    this.$emit('save', response.data);
                    // clear form
                    this.cancel();
                } else {
                    alert('Invalid data');
                };
            },
            cancel() {
                this.create = false;
                this.createForm = {};
            }
        }

    })
