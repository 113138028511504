import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0323cb0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "streams-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mVideoStream = _resolveComponent("mVideoStream")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.pipelines, (pipeline, idx) => {
      return (_openBlock(), _createBlock(_component_mVideoStream, {
        key: idx,
        pipeline: pipeline
      }, null, 8, ["pipeline"]))
    }), 128))
  ]))
}