
import * as api from "@/api";

import oTable from "@/components/03_organisms/oTable.vue"; 

export default {
  components: {
    oTable
  },
  data() {
    return {
      PAGE_SIZE: 10,
      usersApi: {},
      rolesApi: {},

      token: "",
      userFields: [
        {
          title: 'User ID',
          name: 'id',
          type: 'text',
          editable: false,
          creatable: false,
          deletable: false
        },
        {
          title: 'Username',
          name: 'username',
          type: 'text',
          editable: true,
          creatable: true,
          deletable: false
        },
        {
          title: 'Password',
          name: 'password',
          type: 'password',
          editable: true,
          creatable: true,
          deletable: false
        },
        {
          title: 'Roles',
          name: 'roles',
          type: 'tag',
          editable: true,
          creatable: true,
          deletable: false
        }
      ],
      roleFields: [
        {
          title: 'Role ID',
          name: 'id',
          editable: false,
          creatable: false,
          deletable: false
        },
        {
          title: 'Name',
          name: 'name',
          editable: true,
          creatable: true,
          deletable: false
        }
      ]
    }
  },
  computed: {
    authToken() {
      return this.$store.getters['authentication/getToken']
    }
  },
  async created() {
    this.token = await this.authToken;
    this.usersApi = {
      token: this.token,
      load: api.getUsers,
      save: api.addUser,
      update: api.editUser,
      destroy: api.deleteUser
    };
    this.rolesApi = {
      token: this.token,
      load: api.getRoles,
      save: api.addRole,
      update: api.editRole,
      destroy: api.deleteRole
    };
  }
}
