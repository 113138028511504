
import aLabel from "@/components/01_atoms/aLabel.vue";
import aInput from "@/components/01_atoms/aInput.vue";

import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    aLabel,
    aInput
  },
  props: {
    modelValue: { type: [String, Number, Boolean, Object, Array], required: false, default: undefined },
    labelContent: { type: String, required: true },
    labelDescription: { type: String, required: false, default: undefined },
    inputType: { type: String, required: true },
    inputPlaceholder: { type: String, required: false, default: undefined },
    radioOptions: { type: Array<string>, required: false, default: undefined },
    checkboxOptions: { type: Array<string>, required: false, default: undefined },
    checkboxInitialChecked: { type: Array<boolean>, required: false, default: undefined },
    addClass: { type: String, required: false, default: undefined },
  },
emits: ['update:model-value', 'keyup'],
  computed: {
    elemValue: {
      get() {
        return this.modelValue;
      },
      set(value: unknown) {
        this.$emit("update:model-value", value);
      }
    }
  }
})
