
import aButton from "@/components/01_atoms/aButton.vue";
import aVideo from "@/components/01_atoms/aVideo.vue";

import { startPipeline, stopPipeline } from "@/api";

import { WebRtcPeer } from "kurento-utils";
import { defineComponent, PropType } from 'vue'

export default defineComponent({
    components: {
        aButton,
        aVideo
    },
    props: {
        pipeline: {type: Object as PropType<{id: number, active: boolean, listeners: any}>, required: true}
    },
    data() {
        return {
            WebRtcPeer: null as WebRtcPeer | null,
            isRunning: false,
            keepAliveTimer: null
        }
    },
    computed: {
        isAdmin() {
            return this.$store.getters['authentication/isAdmin']
        },
        authToken() {
            return this.$store.getters['authentication/getToken']
        }
    },
    
    async created() {
            this.$options.sockets.onmessage = (rawMessage: {data: string} ) => {
                console.info("Received message:", rawMessage.data);
                const message = JSON.parse(rawMessage.data);
                if (message.pipelineId === null) {
                    console.error("No pipelineId set\n", message);
                    return;
                }
                const pipelineId = parseInt(message.pipelineId);
                console.log("pipelineId: ", pipelineId);
                if (pipelineId != this.pipeline.id) {
                    // console.info("This message wasn't for me: ", pipelineId, "!=", this.pipeline.id);
                    return;
                }

                switch(message.id) {
                    case 'viewerResponse':
                        this.viewerResponse(message);
                        break;
                    case 'stopCommunication':
                        this.dispose();
                        break;
                    case 'iceCandidate':
                        console.log(`Recieved Remote Candidate: ${message.candidate.candidate}`);
                        (this.webRtcPeer as WebRtcPeer).addIceCandidate(message.candidate);
                        break;
                    case 'info':
                        console.info(message);
                        break;
                    default:
                        console.error('Unrecognized message', message);
                }
            }
            window.addEventListener("beforeunload", () => {
                this.stopStream()
            })
        },

        async unmounted() {
            this.stopStream();
        },
    methods: {
        startStream() {
            console.log("Starting stream for pipeline", this.pipeline.id);
            // ĸ
            const videoElem = document.getElementById("video" + this.pipeline.id);
            const options = {
                remoteVideo: videoElem,
                onicecandidate: this.onIceCandidate,
                configuration: {
                    // iceServers: [{
                    //     urls: "turn:turn.helsinki.tools",
                    //     credential: "test",
                    //     username: "test"
                    // }],
                    // iceTransportPolicy: "relay"
                }
            };
            const webRtcPeerCallback = (error) => {
                if(error) {
                // return onError(error);
                    console.error(error);
                    return;
                }
                console.log(this.webRtcPeer as WebRtcPeer);
                this.startKeepAlive();
                this.isRunning = true;
                (this.webRtcPeer as WebRtcPeer).generateOffer((error, offerSdp) => {
                    this.onOfferViewer(error, offerSdp, this.pipeline.id);
                });
            };

            this.webRtcPeer = WebRtcPeer.WebRtcPeerRecvonly(options, webRtcPeerCallback.bind(this));
        },

        startKeepAlive() {
            this.keepAliveTimer = setInterval(() => {
                const message = {
                    id: "keepAlive",
                    pipelineId: this.pipeline.id
                };
                this.sendMessage(message);
            }, 60*1000);
        },

        stopKeepAlive() {
            if(this.keepAliveTimer != null) {
                clearInterval(this.keepAliveTimer);
                this.keepAliveTimer = null;
            }
        },

        stopStream() {
            console.log("Stopping stream for pipeline", this.pipeline.id);
            this.stopKeepAlive();
            if(this.webRtcPeer != null) {
                const message = {
                    id: "stopView",
                    pipelineId: this.pipeline.id
                }
                this.sendMessage(message);
                this.dispose();
            }
            this.isRunning = false;
        },

        mStartPipeline() {
            startPipeline(this.authToken, this.pipeline.id);
        },

        mStopPipeline() {
            stopPipeline(this.authToken, this.pipeline.id);
        },

        sendMessage(rawMessage: unknown) {
            console.log("Sending message: ", rawMessage);
            if (this.$socket != null) {
                const message = JSON.stringify(rawMessage);
                this.$socket.send(message);
            }
        },

        onIceCandidate(candidate: unknown) {
            console.log("Local candidate %o", candidate);
            const message = {
                id: "onIceCandidate",
                pipelineId: this.pipeline.id,
                candidate: candidate
            }
            this.sendMessage(message);
        },

        onOfferViewer(error: unknown, offerSdp: string, id: number) {

            const message = {
                id : 'startView',
                sdpOffer : offerSdp,
                pipelineId: [id]
            }
            this.sendMessage(message);
        },

        dispose() {
            if (this.webRtcPeer != null) {
                this.webRtcPeer.dispose();
                this.webRtcPeer = null;
            }
        },


        viewerResponse(message: { response: string, message: unknown, sdpAnswer: unknown}) {
            if (message.response != 'accepted') {
                const errorMsg = message.message ? message.message : 'Unknow error';
                console.warn('Call not accepted for the following reason: ' + errorMsg);
                this.dispose();
            } else {
                this.webRtcPeer.processAnswer(message.sdpAnswer);
            }
        }
    }
    
})
