import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-165679a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "infobox-container" }
const _hoisted_2 = { class: "infobox-header" }
const _hoisted_3 = {
  key: 0,
  class: "infobox-element-label"
}
const _hoisted_4 = {
  key: 1,
  class: "infobox-element-text"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 3,
  class: "infobox-element-list"
}
const _hoisted_7 = { key: 4 }
const _hoisted_8 = { class: "infobox-rating" }
const _hoisted_9 = {
  key: 0,
  class: "infobox-rating"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aInput = _resolveComponent("aInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content, (element) => {
      return (_openBlock(), _createElementBlock("div", {
        key: element.id,
        class: "infobox-element"
      }, [
        (element.label)
          ? (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(element.label), 1))
          : _createCommentVNode("", true),
        (element.type === 'text')
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(element.content), 1))
          : (element.type === 'link')
            ? (_openBlock(), _createElementBlock("a", {
                key: 2,
                href: element.content.url,
                target: "_blank"
              }, _toDisplayString(element.content.label), 9, _hoisted_5))
            : (element.type === 'list')
              ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(element.content, (tagElem) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: tagElem.id,
                      class: "infobox-element-list-item"
                    }, _toDisplayString(tagElem), 1))
                  }), 128))
                ]))
              : (element.type === 'rating')
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_aInput, {
                      modelValue: _ctx.inputValue,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
                      "input-type": "rating",
                      "add-class": "infobox-rating",
                      "rating-select-handler": _ctx.ratingHandler
                    }, null, 8, ["modelValue", "rating-select-handler"]),
                    _createElementVNode("p", _hoisted_8, _toDisplayString(element.content.avg_rating) + " by " + _toDisplayString(element.content.raters) + " user(s).", 1),
                    (element.content.rating)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_9, "Rated!"))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}