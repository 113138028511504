const jsonObjFromToken = (token: string) => {
  return JSON.parse(atob(token.split('.')[1]))
}

export const userIdFromToken = (token: string) => {
  try {
    return jsonObjFromToken(token).user.id;
  } catch (error) {
    return error;
  }
}

export const userNameFromToken = (token: string) => {
  try {
    return jsonObjFromToken(token).user.username;
  } catch (error) {
    return error;
  }
}

export const userRolesFromToken = (token: string) => {
  try {
    return jsonObjFromToken(token).user.roles;
  } catch(error) {
    return error;
  }
}

export const iatFromToken = (token: string) => {
  try {
    return jsonObjFromToken(token).iat;
  } catch(error) {
    return error;
  }
}

export const expFromToken = (token: string) => {
  try {
    return jsonObjFromToken(token).exp;
  } catch(error) {
    return error;
  }
}
