
import aNavLink from "@/components/01_atoms/aNavLink.vue";
import aLabel from "@/components/01_atoms/aLabel.vue";

import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    aNavLink,
    aLabel,
  },
  props: {
    project: { type: Object, required: true },
    addClass: { type: String, required: false, default: undefined }
  }
})
