import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aLabel = _resolveComponent("aLabel")!
  const _component_aInput = _resolveComponent("aInput")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-el-container", _ctx.addClass])
  }, [
    _createVNode(_component_aLabel, {
      class: "form-el-left",
      "add-class": _ctx.addClass,
      "add-desc": _ctx.labelDescription
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.labelContent), 1)
      ]),
      _: 1
    }, 8, ["add-class", "add-desc"]),
    _createElementVNode("div", {
      class: _normalizeClass(["form-el-right", _ctx.addClass])
    }, [
      _createVNode(_component_aInput, {
        modelValue: _ctx.elemValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.elemValue) = $event)),
        "add-class": _ctx.addClass,
        "input-type": _ctx.inputType,
        placeholder: _ctx.inputPlaceholder,
        "radio-options": _ctx.radioOptions,
        "checkbox-options": _ctx.checkboxOptions,
        "checkbox-initial-checked": _ctx.checkboxInitialChecked
      }, null, 8, ["modelValue", "add-class", "input-type", "placeholder", "radio-options", "checkbox-options", "checkbox-initial-checked"])
    ], 2)
  ], 2))
}