
import aInput from "@/components/01_atoms/aInput.vue";

/**
 * An info box to indicate basic information
 */
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    aInput
  },
  props: {
    title: { type: String, required: true },
    content: { type: Object, required: true },
    ratingHandler: { type: Function, required: false, default: undefined },
    initialRating: Boolean,
    addClass: { type: String, required: false, default: undefined },
    modelValue: { type: [String, Number, Boolean, Array, Object], required: false, default: undefined },
  },
  emits: ["update:model-value"],
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(inputValue: unknown) {
        this.$emit("update:model-value", inputValue);
      }
    }
  }
})
