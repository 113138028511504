
import aLogo from "@/components/01_atoms/aLogo.vue";
import aButton from "@/components/01_atoms/aButton.vue";
import aNavLink from "@/components/01_atoms/aNavLink.vue";
import mDropDown from "@/components/02_molecules/mDropDown.vue";

import { defineComponent } from "vue";

export default defineComponent({
  components: {
    aLogo,
    aButton,
    aNavLink,
    mDropDown
  },
  data() {
    return {
      homeRef:`${location.protocol}://${location.host}`,
      elements: {
        login: {
          class: "header-element",
          label: "LOGIN",
          route: {
            name: "Login"
          }
        },
        about: {
          class: "header-element",
          label: "ABOUT THE LAB",
          route: {
            name: "About"
          }
        },
        tds: {
          class: "header-element",
          label: "THING DESCRIPTIONS",
          route: {
            name: "Thing Descriptions",
          }
        },
        streams: {
          class: "header-element",
          label: "STREAMS",
          route: {
            name: "Streams",
          }
        }
      }
    }
  },
  computed: {
    isConnected() {
      return this.$store.getters['authentication/isConnected']
    },
    isAdmin() {
      return this.$store.getters['authentication/isAdmin']
    },
    getUsername() {
      return this.$store.getters['authentication/getUsername']
    },
    navElements() {
      if (this.isConnected) {
        return [
          this.elements.about,
          this.elements.tds,
          this.elements.streams
        ]
      }
      return [this.elements.login, this.elements.about, this.elements.tds, this.elements.streams]
    },
  },
  methods: {
    async logout() {
      this.$store.dispatch('authentication/logout')
    },
    async requestLogout() {
      await this.logout();
      this.$router.push({
        name: "Homepage",
      });
    }
  }
})
