
import aButton from "@/components/01_atoms/aButton.vue";
import aLabel from "@/components/01_atoms/aLabel.vue";
import aInput from "@/components/01_atoms/aInput.vue";
import oForm from "@/components/03_organisms/oForm.vue";

import { getUser, editUser } from "@/api";

export default {
  components: {
    aLabel,
    aInput,
    aButton,
    oForm
  },
  data() {
    return {
      PAGE_SIZE: 10,

      // Used to change view according to mode.
      editMode: false,
      passwordChange: false,
      allowEdit: false,

      // Detect if changes are made
      changesProfile: false,
      changesPassword: false,

      errorMessage: "",
      validationMessage: "",

      profileFields: {
        username: "",
        roles: ""
      },

      passwordFormFields: [
        {
          type: "password",
          label: "Old Password",
          variable: "old"
        },
        {
          type: "password",
          label: "New Password",
          variable: "new"
        },
        {
          type: "password",
          label: "Confirm Password",
          variable: "confirm"
        }
      ]
    }
  },
  computed: {
    authToken() {
      return this.$store.getters['authentication/getToken']
    },
    username() {
      return this.$store.getters['authentication/getUsername']
    }
  },
  async created() {
    const response = await getUser(this.authToken);
    if (response.status === 200) {
      const profile: { [key: string]: string } = response.data;
      for (const elem in this.profileFields) {
        this.profileFields[elem] = profile[elem];
      }
      this.allowEdit = this.profileFields.roles.includes("admin");
    } // TODO: error handling
  },
  methods: {
    async confirmProfileEdit() {
      if (this.changesProfile) {
        const response = await editUser(
          this.authToken,
          -1,
          this.profileFields
        );
        if (response.status === 200) {
          this.validationMessage = "Changes are saved.";
          this.editMode = false;
          return;
        }
        this.errorMessage = response.data.message;
        this.changesProfile = false;
      } else {
        this.editMode = false;
      }
    },

    async confirmPasswordChange(formData: { [key: string]: string }) {
      if (!formData.new && !formData.old && !formData.confirm) {
        this.passwordChange = false;
        return;
      }
      if (formData.new === formData.confirm) {
        const response = await editUser(
          this.authToken,
          -1,
          {
            password: {
              old: formData.old,
              new: formData.new
            }
          }
        );
        if (response.status === 200) {
          this.validationMessage = "Password is succesfully changed.";
          this.passwordChange = false;
        } else {
          this.errorMessage = "Old password is incorrect.";
        }
      } else {
        this.errorMessage = "Passwords do not match.";
      }
    },

    resetMessage() {
      this.errorMessage = "";
      this.validationMessage = "";
    },

    onKeyPressHandler(field: string) {
      this.resetMessage();
      if (field === "profile") this.changesProfile = true;
      if (field === "password") this.changesPassword = true;
    }
  }
}
