import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-105263e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "json-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueJsonPretty = _resolveComponent("VueJsonPretty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VueJsonPretty, {
      data: _ctx.content,
      "show-double-quotes": true,
      "show-line": true,
      "show-length": true,
      "highlight-mouseover-node": true
    }, null, 8, ["data"])
  ]))
}