
import { defineComponent } from 'vue'

/**
 * A basic button component
 */
export default defineComponent({
  props: {
     // Value emitted on-click
     btnValue: { type: [String, Number, Boolean, Object, Array], required: false, default: undefined },
     // To allow disabling of button
     isDisabled: {type: Boolean, default: false},
     // Event emitted on-click
     btnEvent: { type: String, required: true },
     // Additional css classes for component
     addClass: undefined
  },
  methods: {
    onClick() {
      if (this.btnValue) this.$emit(this.btnEvent, this.btnValue);
      else this.$emit(this.btnEvent);
    }
  }
})
