
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    width: { type: String, default: "100%" },
    height: { type: String, default: "100%" },
    muted: { type: Boolean, default: false },
    id: { type: Number, required: true },
    sources: { type: Array<{url: string, type: string}>, required: false, default: [] }
  }
})
