
    import aButton from "@/components/01_atoms/aButton.vue";
    import aInput from "@/components/01_atoms/aInput.vue";

    import { defineComponent, PropType } from 'vue'

    export default defineComponent({
        components: {
            aButton,
            aInput
        },
        props: {
            item: { type: Object as PropType<{id: number, [k: string]: unknown}>, required: true },
            fields: {type: Array<{title: string, name: string, type: string, editable: boolean}>, required: true },
            editable: Boolean,
            deletable: Boolean,
            api: {type: Object, required: true}
        },
        emits: ["update", "destroy"],
        data() {
            return {
                editMode: false,
                editForm: {id: 0}
            }
        },
        methods: {
            edit() {
                this.editMode = true;

                // get fields for the form
                for(const field of this.fields) {
                    this.editForm[field.name] = this.item[field.name];
                }
            },
            cancelEdit() {
                this.editMode = false;
                // clear the form
                for(const field of this.fields) {
                    this.editForm[field.name] = '';
                }
            },
            async update(oldItem: { id: number}, newItem: {id: number}) {
                this.edit();
                // send request to update data
                const response = await this.api.update(this.api.token, newItem.id, newItem);
                if(response.status === 200) {
                    this.$emit('update', response.data);
                    this.cancelEdit();
                } else {
                    alert('Invalid data');
                };
            },
            async destroy(item: {id: number}) {
                const response = await this.api.destroy(this.api.token, item.id);
                if(response.status === 200) {
                    this.$emit('destroy', item);
                } else {
                    alert('Invalid data');
                };
            }
            
        }
    })
