import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-273d9247"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-container" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aLogo = _resolveComponent("aLogo")!
  const _component_aNavLink = _resolveComponent("aNavLink")!
  const _component_aButton = _resolveComponent("aButton")!
  const _component_mDropDown = _resolveComponent("mDropDown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", { href: _ctx.homeRef }, [
      _createVNode(_component_aLogo, { "add-class": "header-logo-image header-logo" })
    ], 8, _hoisted_2),
    (_ctx.isConnected)
      ? (_openBlock(), _createBlock(_component_mDropDown, {
          key: 0,
          "add-class": "default header-element",
          "close-on-click": true
        }, {
          dropDownLabel: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getUsername), 1)
          ]),
          dropDownOptions: _withCtx(() => [
            _createVNode(_component_aNavLink, {
              "add-class": 'dropDown-element',
              to: { name: 'Profile', params: { id: _ctx.getUsername }}
            }, {
              default: _withCtx(() => [
                _createTextVNode("Profile")
              ]),
              _: 1
            }, 8, ["to"]),
            (_ctx.isAdmin)
              ? (_openBlock(), _createBlock(_component_aNavLink, {
                  key: 0,
                  "add-class": 'dropDown-element',
                  to: { name: 'User Management' }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Manage Users")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_aButton, {
              "add-class": 'dropDown-element',
              "btn-event": "btn-logout",
              onBtnLogout: _ctx.requestLogout
            }, {
              default: _withCtx(() => [
                _createTextVNode("Logout")
              ]),
              _: 1
            }, 8, ["onBtnLogout"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navElements, (element) => {
      return (_openBlock(), _createBlock(_component_aNavLink, {
        key: element.route.name,
        to: element.route,
        class: _normalizeClass(element.class),
        "active-class": 'active'
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(element.label), 1)
        ]),
        _: 2
      }, 1032, ["to", "class"]))
    }), 128))
  ]))
}