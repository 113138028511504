import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mProjectTag = _resolveComponent("mProjectTag")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["project-tag-container", _ctx.addClass])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (element) => {
      return (_openBlock(), _createBlock(_component_mProjectTag, {
        key: element.id,
        project: element
      }, null, 8, ["project"]))
    }), 128))
  ], 2))
}