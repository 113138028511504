
import showdown from 'showdown';

import { defineComponent } from 'vue'

const md = new showdown.Converter({
  simpleLineBreaks: true,
  ghCompatibleHeaderId: true
})

export default defineComponent({
  props: {
    content: {type: String, required: true}
  },
  computed: {
    compiledMarkdown() {
      return md.makeHtml(this.content)
    }
  }
})
